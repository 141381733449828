import Landmark from '../components/landmark-adapter';
import JshModule from "@core/scripts/helper/jsh-module";

const {moduleScope, config} = JshModule('country-selection');
let countrySelectionNode = moduleScope.querySelector("[jsh-country-selection__select]");
countrySelectionNode.addEventListener("change", selectCountryOption);

function selectCountryOption() {
    Landmark.sendLandmark(config);
    window.location = countrySelectionNode.value;
}